<template>
  <div :key="UserDeletedRequestsList.length" id="data-list-list-view" class="data-list-container">
    <!-- Table -->
    <vs-table
      ref="table"
      v-model="selected"
      :max-items="itemsPerPage"
      search
      :data="UserDeletedRequestsList"
      pagination
    >
      <!-- Table Header -->
      <div
        slot="header"
        class="
          flex flex-wrap-reverse items-center flex-grow justify-between
        "
      >
        <!-- Items per Page Dropdown -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="
              p-4 border border-solid rounded-full cursor-pointer
              d-theme-border-grey-light d-theme-dark-bg
              flex items-center justify-between font-medium
            "
          >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <!-- Table Body -->
      <template slot="thead">
        <vs-th sort-key="FullName">{{ $t("FullName") }}</vs-th>
        <vs-th sort-key="PhoneNumber">{{ $t("PhoneNumber") }}</vs-th>
        <vs-th>{{ $t("Actions") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.firstName + ' ' + tr.lastName }}</vs-td>
            <vs-td>{{ tr.PhoneNumber }}</vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="openConfirm(tr)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moduleUserDeletedRequest from "@/store/UserDeletedRequest/moduleUserDeletedRequest.js";

export default {
  components: {},
  data() {
    return {
      selected: [],
      UserDeletedRequestsList: [],
      search: {},
      showSearchSidebar: false,
      itemsPerPage: 10,
      isMounted: false,
      addNewDataSidebar: false, // Data Sidebar
      sidebarData: {},
      rowDataForDelete: {},
      rowDataApprove: {},
      activeConfirm: false,
    };
  },

  computed: {
    currentPage() {
  return this.isMounted ? this.$refs.table.current : 0;
},
// UserDeletedRequests() {
//       return this.$store.state.UserDeletedRequestList.UserDeletedRequests;
//     },
  },

  methods: {
    deleteData(data) {
      this.$store
        .dispatch("UserDeletedRequestList/deleteThisUser", data)
        .then(() => {
          this.$store
            .dispatch("UserDeletedRequestList/fetchAllUserDeletedRequestList")
            .then(() => {
              this.$vs.loading.close();
              this.$vs.notify({
                color: "green",
                title: this.$t("Approved"),
                text: this.$t("The selected record was successfully Approved"),
              });
            });
        })
        .catch(() => {});
    },

    ApproveToAdd(data) {
      debugger;
      this.$store
        .dispatch("UserDeletedRequestList/addIApproveRequesttem", data)
        .then(() => {
          window.showSuccessApproveRequest();
          this.getAllRequests();
          window.location.reload();
        })
        .catch(() => {});
    },

    getAllRequests() {
      this.$store
        .dispatch("UserDeletedRequestList/fetchAllUserDeletedRequestList")
        .then((response) => {
        this.UserDeletedRequestsList= response.data;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
          window.showError();
        });
    },

    formatDate(date) {
      return date ? new Date(date).toLocaleDateString() : "";
    },

    openApprove(data) {
      debugger;
      this.rowDataApprove = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("AreYouSureYouWantToApproveRequest"),
        accept: this.acceptApprove,
      });
    },

    acceptApprove() {
      debugger;
      this.ApproveToAdd(this.rowDataApprove);
      window.showSuccessApproveRequest();
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("Areyousureyouwantdeletethisrecord"),
        accept: this.acceptAlert,
      });
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
    },
  },

  created() {
    this.$vs.loading();
    if (!moduleUserDeletedRequest.isRegistered) {
      this.$store.registerModule("UserDeletedRequestList", moduleUserDeletedRequest);
      moduleUserDeletedRequest.isRegistered = true;
    }

    this.$store
      .dispatch("UserDeletedRequestList/fetchAllUserDeletedRequestList")
      .then((response) => {
        this.UserDeletedRequestsList= response.data;
        this.$vs.loading.close();
      })
      .catch(() => {
        this.$vs.loading.close();
        window.showError();
      });
  },
  mounted() {
    this.isMounted = true;
  } ,

};
</script>
