/*=========================================================================================
  File Name: modulecourseClassMutations.js
  Description: courseClass Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/Driver/pixinvent
==========================================================================================*/

export default {
  ADD_UserDeletedRequest(state, item) {
    state.UserDeletedRequest.unshift(item);
  },

  SET_UserDeletedRequest(state, UserDeletedRequest) {
    debugger;
    state.UserDeletedRequests = UserDeletedRequest;
  },

  SET_ALLUserDeletedRequest(state, allUserDeletedRequest) {
    state.UserDeletedRequestS = allUserDeletedRequest;
  },

  UPDATE_UserDeletedRequest(state, item) {
    const Index = state.UserDeletedRequests.findIndex(p => p.Id == item.Id);
    Object.assign(state.UserDeletedRequests[Index], item);
  },
  SET_STATE_LIST(state, data) {
    state.StateList = data;
  },
  ADD_STATE(state,data)
   { state = data;
  },
};
