/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {

  deleteThisUser({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/AdminAccount/DeleteUserAccount", item)
        .then(response => {
          if (response.status == 200) {
            resolve(response);
            fetchAllUserDeletedRequestList();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchAllUserDeletedRequestList({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("/AdminAccount/GetAllUserRequestToDeleteAccount")
        .then(response => {

          if (response.status == 200) {
            debugger;
            commit("SET_UserDeletedRequest", response.data);
            resolve(response);

          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
